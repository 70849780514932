import React, { Component } from 'react'

class Finishes extends Component {
  state = {
    colorCode: this.props.part.colorCode === null ? '' : this.props.part.colorCode,
  }

  handleColorBlur = (event) => {
    this.props.setColor(this.props.part.id, event.target.value)
  }

  handleColorClick = (color) => {
    this.props.setColor(this.props.part.id, color)
  }

  handleFinishClick = (finishId) => {
    this.props.setColor(this.props.part.id, null)
    this.props.setFinish(this.props.part.id, finishId)
  }

  handleInputChange = (name, event) => {
    const value = event.target.value
    
    this.setState({
      [name]: value
    })
  }

  handleInputBlur = (name, event) => {
    const value = event.target.value
    const part = this.props.part

    switch(name) {
      case 'colorCode':
        this.props.setColorCode(part.id, this.state.colorCode)
        break
      default:
        null
    }
  }

  renderColorOptions = (finish) => {
    if(finish.pantone) {
      return (
        <div>
          <input 
            type="text" 
            className="form-control" 
            placeholder="Pantone #"
            style={{width: '75%'}}
            value={this.state.colorCode}
            onChange={(e) => this.handleInputChange('colorCode', e)}
            onBlur={this.handleColorBlur}/>
        </div>
      )
    } else if(finish) {
      return finish.getColors().map((option, index) => (
        <div className="radio" key={index}>
          <label>
            <input  type="radio"
                    onChange={() => this.handleColorClick(option)}
                    checked={option === this.props.part.color} />
            {option}
          </label>
        </div>
      ))
    }
  }

  render() {
    const { 
      part, 
      finishes,
      finish,
    } = this.props

    return (
      <div className="flex-horizontal flex-horizontal--align-items-flex-start">
        <div className="finishes-view" 
             style={{flexBasis: '50%'}}>
          <div>
            <h6 className="field-title">Finishes</h6>
            {
              finishes.map((option, index) => {
                return (
                  <div className="form-check" key={index}>
                    <label>
                      <input
                        type="radio"
                        className="form-check-input"
                        onChange={() => this.handleFinishClick(option.id)}
                        checked={option.id === part.finish}
                      />
                      {option.name}
                    </label>
                  </div>
                )
              })
            }
          </div>

          <div style={{marginLeft: '2em'}}>
            <h6 className="field-title">Colors</h6>
            { part.finish !== null && this.renderColorOptions(finish) }
          </div>
        </div>
      </div>
    )
  }
}

Finishes.propTypes = {}
Finishes.defaultProps = {}

export default Finishes
