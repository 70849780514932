import React from 'react'
import cn from 'classnames'

const Button = ({text, onClick, type, style}) => {
  const buttonClass = cn("qe-button2", {
    "qe-button2--info": type === 'info',
    "qe-button2--success": type === 'success',
    "qe-button2--plain": type === 'plain'
  })

  return (
    <button onClick={onClick} className={buttonClass} style={style}>
      {text}
    </button>
  )
}

export default Button