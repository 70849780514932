import Immutable from 'immutable'

export const Material = Immutable.Record({
  id: null,
  name: '',
  description: '',
  materialType: '',
  finishes: Immutable.List([]),
  colorOptions: Immutable.List([]),
  finishOptions: Immutable.List([]),
  pricePerUnit: null,
  units: '',
  fabricationMethodId: null
});

export const MaterialMap = Immutable.OrderedMap;