import React from 'react'
import cn from 'classnames'
import Materials from './Materials'
import Features from './Features'
import Finishes from './Finishes'
import Notes from './Notes'

class PartBody extends React.Component {
  state = {
    activeTab: 'materials'
  }

  renderTab = () => {
    const {
      materials,
      material,
      part,
      finishes,
      finish,
      setColor,
      setFinish,
      setColorCode,
      setThreadedHoles,
      setInserts,
      setMaterialId,
      setNotification,
      clearNotification,
      threadedActive,
      insertsActive,
      supplementalFiles,
      setGeneralNotes,
      uploadSupplementalFile,
      deleteSupplementalFile
    } = this.props

    switch(this.state.activeTab) {
      case 'materials':
        return <Materials
                  materials={materials}
                  part={part}
                  material={material}
                  setMaterialId={setMaterialId} />;
      case 'features':
        return <Features
                  part={part}
                  setThreadedHoles={setThreadedHoles}
                  setInserts={setInserts}
                  threadedActive={threadedActive}
                  insertsActive={insertsActive}
                />;
      case 'finishes':
        return <Finishes
                  part={part}
                  finishes={finishes}
                  finish={finish}
                  setFinish={setFinish}
                  setColor={setColor}
                  setColorCode={setColorCode}
                />;
      case 'notes':
        return <Notes
                part={part}
                supplementalFiles={supplementalFiles}
                setGeneralNotes={setGeneralNotes}
                uploadSupplementalFile={uploadSupplementalFile}
                deleteSupplementalFile={deleteSupplementalFile}
              />
    }
  }

  setActiveTab = (tab) => {
    const { part, setNotification, clearNotification} = this.props

    if(part.materialId === null && tab !== "materials") {
      setNotification(part.id, "Please select a material.")
    } else {
      clearNotification();
      this.setState({ activeTab: tab })
    }
  }

  render() {
    const { activeTab } = this.state

    return (
      <div className="part-body">
        <ul className="nav nav-tabs part-body__nav">
          <li className={cn("nav-item", {active: activeTab === 'materials'})}>
            <a  onClick={() => this.setActiveTab("materials")}
                className={cn("nav-link", {active: activeTab === 'materials'})}>Material</a>
          </li>
          <li className={cn("nav-item", {active: activeTab === "features"})}>
            <a  onClick={() => this.setActiveTab("features")}
                className={cn("nav-link", {active: activeTab === 'features'})}>Features</a>
          </li>
          <li className={cn("nav-item", {active: activeTab === "finishes"})}>
            <a  onClick={() => this.setActiveTab("finishes")}
                className={cn("nav-link", {active: activeTab === 'finishes'})}>Finish</a>
          </li>
          <li className={cn("nav-item", {active: activeTab === "notes"})}>
            <a  onClick={() => this.setActiveTab("notes")}
                className={cn("nav-link", {active: activeTab === 'notes'})}>Notes</a>
          </li>
        </ul>
        <div className="part-body__content">
          { this.renderTab() }
        </div>
      </div>
    )
  }
}

export default PartBody
