import Immutable from 'immutable'
import { Material, MaterialMap } from '../models/Material'
import * as constants from '../constants'

const init = new MaterialMap();

const mergeMaterialEntities = (state, newMaterials) => {
  return state.merge(newMaterials.map((material) => new Material(material)))
}

export const materials = (state = init, action) => {
  switch(action.type) {
    case constants.UPDATE_MATERIAL_ENTITIES:
      return mergeMaterialEntities(state, Immutable.fromJS(action.payload))
    default:
      return state
  }
}