import { combineReducers } from 'redux'
import { fabricationMethods } from './fabricationMethods'
import { finishes } from './finishes'
import { materials } from './materials'
import { partEntities, activePartId } from './parts'
import { metaParts } from './metaParts'
import { supplementalFiles } from './supplementalFiles'
import { quote } from './quote'
import { user } from './user'
import { loading } from './loading'

const entityReducer = combineReducers({
  fabricationMethods,
  finishes,
  materials,
  parts: partEntities,
  metaParts,
  supplementalFiles
})

const rootReducer = combineReducers({
  entities: entityReducer,
  quote,
  user,
  activePartId,
  loading
})

export default rootReducer