import React from 'react'
import cn from 'classnames'

const Progress = ({progress}) => {
  const active = progress === 100

  const barClass = cn("progress-bar", {
    "progress-bar--success": active
  })
  
  const textClass = cn("progress-container__text", {
    "progress-container__text--success": active
  })

  return (
    <div className="progress-container">
      <div className="progress">
        <div className={barClass} style={{width: progress + '%'}} />
      </div>
      <div>
        <h5 className={textClass}>{progress.toFixed(1)}%</h5>
      </div>
    </div>
  )
}

Progress.defaultProps = {
  progress: 100
}

export default Progress