import Immutable from 'immutable'

export const Part = Immutable.Record({
  id: null,
  color: '',
  finish: null,
  finishNotes: '',
  generalNotes: '',
  materialId: null,
  metaPartId: null,
  name: '',
  quantity: 1,
  shopifyProductId: '',
  shopifyVariantId: '',
  status: '',
  supplementalFiles: Immutable.List([]),
  fabricationMethodId: 1,
  view: 'material',
  price: 0.00,
  totalPrice: 0.00,
  threadedHoles: null,
  inserts: null,
  notification: '',
  colorCode: '',
  textureCode: ''
});

export const PartMap = Immutable.OrderedMap;