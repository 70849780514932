import React, { useState } from 'react'
import cn from 'classnames'
import Dropzone from 'react-dropzone'

const SupplementalFileDropbox = ({partId, onDrop}) => {
  const [file, setFile] = useState({})
  const [progress, setProgress] = useState(0)

  const clearState = () => {
    setFile({})
    setProgress(0)
  }

  const handleDrop = async (files) => {
    setFile(files[0])

    await onDrop(partId, files[0])
    clearState()
  }

  // Updates the progress indicator
  const handleProgress = (prog) => {
    if(typeof prog.percent !== 'undefined') {
      setProgress(prog.percent)
    }
  }

  return (
    <Dropzone onDrop={handleDrop} multiple={false}>
      {({getRootProps, getInputProps, isDragActive}) => (
        <div {...getRootProps({
          className: cn("supplemental-file-dropbox", {"supplemental-file-dropbox--active": isDragActive})
        })}>
          <input {...getInputProps()}/>
          { progress > 0
            ? <span>Processing...</span>
            : <span>Upload</span> }
        </div>
      )}
    </Dropzone>
  )
}

export default SupplementalFileDropbox
