import Immutable from 'immutable'
import { Quote } from '../models/Quote'
import * as constants from '../constants'

const init = new Quote();

export const quote = (state = init, action) => {
  switch(action.type) {
    case constants.RECEIVE_QUOTE:
      return state.merge(Immutable.fromJS(action.payload))
    case constants.SET_QUOTE_ID:
      return state.update('id', id => action.payload )
    case constants.RECEIVE_PART:
      return state.update('parts', p => p.push(action.payload))
    case constants.REMOVE_PART:
      return state.update('parts', p => p.delete(p.indexOf(action.payload.toString())))
    default:
      return state
  }
}


