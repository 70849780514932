import React, { Component } from 'react'
import { connect } from 'react-redux'
import CoreLayout from './CoreLayout'
import Quote from './containers/Quote'
import Upload from './containers/Upload'
import { UploadManagerContext, FilesContext } from '../contexts'

class Engine extends Component {
  state = {
    files: []
  }

  setFiles = (files) => this.setState({ files })

  wrapUploadComponent = (component) => (
    <UploadManagerContext.Provider value={{updateFiles: this.setFiles}}>
      {component}
    </UploadManagerContext.Provider>
  )

  wrapQuoteComponent = (component) => (
    <UploadManagerContext.Provider value={{updateFiles: this.setFiles}}>
      <FilesContext.Provider value={this.state.files}>
        {component}
      </FilesContext.Provider>
    </UploadManagerContext.Provider>
  )

  render() {
    const {
      entitiesLoaded,
      partsCount,
      quote
    } = this.props

    return (
      <CoreLayout>
        {
          entitiesLoaded && quote.id !== null && partsCount > 0
            ? this.wrapQuoteComponent(<Quote quote={quote} />)
            : this.wrapUploadComponent(<Upload />)
        }
      </CoreLayout>
    )
  }
}

Engine.defaultProps = {
  parts: []
}

const mapStateToProps = (state) => {
  const quote = state.quote
  const partsCount = quote.parts.size

  // Don't attempt to advance unless all of the background entities
  // have made it into the state.
  const entitiesLoaded =
      state.entities.fabricationMethods.size > 0
        && state.entities.materials.size > 0
        && state.entities.finishes.size > 0

  return {
    partsCount,
    quote,
    entitiesLoaded
  }
}

export default connect(mapStateToProps,{})(Engine)
