import * as constants from '../constants'

/*************************
 * Action Creators *
 *************************/

/*
 * User Actions
 * ===============
 */

 export const setUser = (user) => {
   return {
     type: constants.SET_USER,
     payload: {
       id: user.id,
       email: user.email,
       firstName: user.first_name,
       lastName: user.last_name,
     }
   }
 }

/*
 * Active Part 
 * ===========
 */

 export const setActivePartId = (partId) => {
  return {
    type: constants.SET_ACTIVE_PART_ID,
    payload: partId
  }
 }

 export const setLoading = (isLoading) => {
  return {
    type: constants.SET_LOADING,
    payload: isLoading
  }
 }

/*
 * Quote Actions
 * ===============
 */

 export const receiveQuote = (json) => {
  return {
    type: constants.RECEIVE_QUOTE,
    payload: json
  }
 }

export const setQuoteId = (quoteId) => {
  return {
    type: constants.SET_QUOTE_ID,
    payload: quoteId
  }
}

/*
 * Part Actions
 * ===============
 */

 export const setNotification = (partId, notification) => {
  return {
    type: constants.SET_NOTIFICATION,
    payload: {
      partId,
      notification
    }
  }
 }

 export const clearNotification = (partId) => {
  return {
    type: constants.CLEAR_NOTIFICATION,
    payload: {
      partId
    }
  }
 }

export const removePart = (partId) => {
  return {
    type: constants.REMOVE_PART,
    payload: partId
  }
}

export const setPrice = (partId, price) => {
  return {
    type: constants.SET_PRICE,
    payload: {
      partId: partId,
      price: price
    }
  }
}

export const setTotalPrice = (partId, totalPrice) => {
  return {
    type: constants.SET_TOTAL_PRICE,
    payload: {
      partId: partId,
      totalPrice: totalPrice
    }
  }
}

export const setFabricationMethodId = (partId, fabMethodId) => {
  return {
    type: constants.SET_FAB_METHOD,
    payload: {
      partId: partId,
      fabricationMethodId: fabMethodId
    }
  }
}

export const setMaterialId = (partId, materialId) => {
  return {
    type: constants.SET_MATERIAL,
    payload: {
      partId: partId,
      materialId: materialId
    }
  }
}

export const setQuantity = (partId, quantity) => {
  return {
    type: constants.SET_QUANTITY,
    payload: {
      partId: partId,
      quantity: quantity
    }
  }
}

export const setName = (partId, name) => {
  return {
    type: constants.SET_NAME,
    payload: {
      partId: partId,
      name: name
    }
  }
}

export const setColor = (partId, color) => {
  return {
    type: constants.SET_COLOR,
    payload: {
      partId: partId,
      color: color
    }
  }
}

export const setFinish = (partId, finish) => {
  return {
    type: constants.SET_FINISH,
    payload: {
      partId: partId,
      finish: finish
    }
  }
}

export const setFinishNotes = (partId, finishNotes) => {
  return {
    type: constants.SET_FINISH_NOTES,
    payload: {
      partId: partId,
      finishNotes: finishNotes
    }
  }
}

export const setGeneralNotes = (partId, generalNotes) => {
  return {
    type: constants.SET_GENERAL_NOTES,
    payload: {
      partId: partId,
      generalNotes: generalNotes
    }
  }
}

export const setThreadedHoles = (partId, threadedHoles) => {
  return {
    type: constants.SET_THREADED_HOLES,
    payload: {
      partId: partId,
      threadedHoles: threadedHoles
    }
  }
}

export const setInserts = (partId, inserts) => {
  return {
    type: constants.SET_INSERTS,
    payload: {
      partId: partId,
      inserts: inserts
    }
  }
}

export const setColorCode = (partId, colorCode) => {
  return {
    type: constants.SET_COLOR_CODE,
    payload: {
      partId,
      colorCode
    }
  }
}

export const setTextureCode = (partId, textureCode) => {
  return {
    type: constants.SET_TEXTURE_CODE,
    payload: {
      partId,
      textureCode
    }
  }
}

export const setPartView = (partId, view) => {
  return {
    type: constants.SET_PART_VIEW,
    payload: {
      partId: partId,
      view: view
    }
  }
}

/*
 * API Request Actions
 * ===================
 */

 export const requestFabricationMethods = () => {
   return {
     type: constants.REQUEST_FABRICATION_METHODS
   }
 }

 export const beginPartUpload = () => {
   return {
     type: constants.BEGIN_PART_UPLOAD
   }
 }

 /*
  * API Receive Actions
  * ===================
  */


export const receivePart = (json) => {
  return {
    type: constants.RECEIVE_PART,
    payload: json
  }
}

export const removeSupplementalFile = (fileId) => {
  return {
    type: constants.REMOVE_SUPP_FILE,
    payload: fileId
  }
}

/*
 * Entity Update Actions
 * =====================
 */

export const updateFabricationMethodEntities = (json) => {
  return {
    type: constants.UPDATE_FABRICATION_METHOD_ENTITIES,
    payload: json
  }
}

export const updateFinishEntities = (json) => {
  return {
    type: constants.UPDATE_FINISH_ENTITIES,
    payload: json
  }
}

export const updateMaterialEntities = (json) => {
  return {
    type: constants.UPDATE_MATERIAL_ENTITIES,
    payload: json
  }
}

export const updatePartEntities = (json) => {
  return {
    type: constants.UPDATE_PART_ENTITIES,
    payload: json
  }
}

export const updateMetaPartEntities = (json) => {
  return {
    type: constants.UPDATE_META_PART_ENTITIES,
    payload: json
  }
}

export const updateSupplementalFileEntities = (json) => {
  return {
    type: constants.UPDATE_SUPPLEMENTAL_FILE_ENTITIES,
    payload: json
  }
}
