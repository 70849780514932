import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import cn from 'classnames'

class AddPartDropbox extends Component {
  state = {
    file: {},
    progress: 0
  }

  clearState = () => {
    this.setState({
      file: {},
      progress: 0
    })
  }

  handleDrop = (files) => {
    this.setState({
      file: files[0]
    })

    this.props.onDrop(
        this.props.quoteId, 
        files[0]
      )
      .then(this.clearState)
  }

  render() {
    return (
      <Dropzone onDrop={this.handleDrop} multiple={false}>
        {({getRootProps, getInputProps, isDragActive}) => (
          <div {...getRootProps({
            className: cn("add-part-dropbox", {"add-part-dropbox--active": isDragActive})
          })}>
            <input {...getInputProps()}/>
            { this.state.progress > 0
              ? <h5 className="add-part-dropbox__text">Processing your file...</h5>
              : <h5 className="add-part-dropbox__text">Add Part To Quote <i className="fas fa-plus"></i></h5> }
          </div>
        )}
      </Dropzone>
    )
  }
}

export default AddPartDropbox
