import React from 'react'
import Parts from '../common/parts/Parts'
import QuoteHeader from '../common/QuoteHeader'

const QuoteView = ({
  quote,
  totalPrice,
  cartUrl,
  uploadPart,
  loading,
  parts
}) => (
  <div className="quote">
    <QuoteHeader
      quote={quote}
      totalPrice={totalPrice}
      cartUrl={cartUrl}
      uploadPart={uploadPart}
      loading={loading}
    />
    <Parts parts={parts} />
  </div>
)

export default QuoteView
