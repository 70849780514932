import Immutable from 'immutable'
import { SupplementalFile, SupplementalFileMap } from '../models/SupplementalFile'
import * as constants from '../constants'

const init = new SupplementalFileMap();

const mergeFileEntities = (state, newFiles) => {
  return state.merge(newFiles.map( file => new SupplementalFile(file)))
}

export const supplementalFiles = (state = init, action) => {
  switch(action.type) {
    case constants.UPDATE_SUPPLEMENTAL_FILE_ENTITIES:
      return mergeFileEntities(state, Immutable.fromJS(action.payload))
    case constants.REMOVE_SUPP_FILE:
      return state.delete(action.payload.toString())
    default:
      return state
  }
}