import React, { Component } from 'react'
import cn from 'classnames'
import Dropzone from 'react-dropzone'
import Progress from './Progress'
import CenteredSpinner from './CenteredSpinner'
import { UploadManagerContext } from '../../contexts'

const maxFiles = 10

class Dropbox extends Component {
  static contextType = UploadManagerContext
  state = {
    file: {},
    progress: 0
  }

  handleDrop = (files) => {
    // If maxFiles is exceeded, [] gets passed into this
    if(files.length == 0) {
      this.props.onError("Too many files. You may only upload 10 files at once.")
      return false;
    }

    const first_file = files.shift()

    this.setState({
      file: first_file
    })

    this.context.updateFiles(files)

    this.props.onDrop(
        this.props.userId,
        first_file,
        this.handleProgress,
        (error) => {
          this.setState({
            file: {},
            progress: 0
          })

          this.props.onError('There was a problem uploading your file.  Please ensure that it is a STEP file.  If you still have trouble, please contact us using the link below.')
        }
      )
  }

  // Updates the progress indicator
  handleProgress = (prog) => {
    if(typeof prog.percent !== 'undefined') {
      this.setState({
        progress: prog.percent
      })
    }
  }

  renderProgress = (progress) => {
    if(progress === 100) {
      return (
        <div>
          <span className="dropbox__text">Analyzing your parts...</span>
          <CenteredSpinner />
        </div>
      )
    } else if(progress > 0 ) {
      return (
        <div>
          <span className="dropbox__text">Uploading - <strong>{this.state.file.name}</strong></span>
          <Progress progress={progress} />
          <CenteredSpinner />
        </div>
      )
    } else {
      return (
        <div>
          <span className="dropbox__text">
            Drag files here to upload or <span className="dropbox__link">Browse files</span>
          </span>
        </div>
      )
    }
  }

  render() {
    return (
      <Dropzone onDrop={this.handleDrop} multiple={true} maxFiles={maxFiles}>
        {({getRootProps, getInputProps, isDragActive}) => (
          <div {...getRootProps({
            className: cn("dropbox", {"dropbox--active": isDragActive})
          })}>
            <input {...getInputProps()}/>
            <div style={{textAlign: 'center'}}>
              { this.renderProgress(this.state.progress) }
            </div>
          </div>
        )}
      </Dropzone>
    )
  }
}

export default Dropbox
