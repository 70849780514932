import React from 'react'
import { connect } from 'react-redux'
import { uploadPart } from '../../actions/requests'
import { CartHelper } from '../../utils'
import QuoteView from '../views/QuoteView'
import OrderView from '../views/OrderView'

class Quote extends React.Component {
  render() {
    const {
      quote,
      parts,
      totalPrice,
      cartUrl,
      uploadPart,
      loading,
      view
    } = this.props

    return view === 'order'
      ? <OrderView
          quote={quote}
          parts={parts}
          cartUrl={cartUrl}
        />
      : <QuoteView
          quote={quote}
          totalPrice={totalPrice}
          cartUrl={cartUrl}
          uploadPart={uploadPart}
          loading={loading}
          parts={parts}
        />
  }
}

const mapStateToProps = (state, ownProps) => {
  const loading = state.loading
  const quote = ownProps.quote

  const parts = state.quote.parts.map(part => {
    return state.entities.parts.get(part.toString())
  })

  const totalPrice = state.entities.parts.reduce((total, part) => {
    return total + part.totalPrice
  }, 0)

  const cartHelper = new CartHelper(state)
  const cartUrl = cartHelper.getCartUrl()
  const view = 'default'

  return {
    parts,
    totalPrice,
    cartUrl,
    loading,
    view
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    uploadPart: (file, onProgress) => dispatch(uploadPart(file, onProgress)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Quote)
