import React from 'react'
import GeneralNotes from './GeneralNotes'
import SupplementalUploads from './SupplementalUploads'

const Notes = ({
  part,
  supplementalFiles,
  setGeneralNotes,
  uploadSupplementalFile,
  deleteSupplementalFile
}) => (
  <div className="notes-container">
    <div className="notes-container__left" >
      <GeneralNotes
        part={part}
        notes={part.generalNotes}
        onBlur={setGeneralNotes}
      />
    </div>
    <div className="notes-container__right" >
      <SupplementalUploads
        part={part}
        onDrop={uploadSupplementalFile}
        files={supplementalFiles}
        onDelete={deleteSupplementalFile}
      />
    </div>
  </div>
)

export default Notes
