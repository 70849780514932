import React from 'react'
import FeaturesCheckbox from './FeaturesCheckbox'

class Features extends React.Component {
  render() {
    const { 
      part,
      setThreadedHoles,
      setInserts,
      threadedActive,
      insertsActive 
    } = this.props

    return (
      <div>
        <h6 className="field-title">Features</h6>
        <div className="features-view">
          <div>
            <FeaturesCheckbox
              placeholder="Quantity"
              label="Threads + Tapped holes"
              partId={part.id}
              fieldValue={part.threadedHoles}
              onChange={setThreadedHoles}
              checked={threadedActive} />

            <FeaturesCheckbox
              placeholder="Quantity"
              label="Inserts"
              partId={part.id}
              fieldValue={part.inserts}
              onChange={setInserts}
              checked={insertsActive} />
          </div>
          <div className="features-view features-view__right">
            <p className="features-view features-view__text">Please upload PDF or image noting type and location of all features in the NOTES tab.</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Features
