import React, { Component } from 'react'
import Part from '../../containers/Part'

class Parts extends Component {
  render() {
    return (
      <div>
        {
          this.props.parts.map(part => {
            return <Part key={part.id} part={part} />
          })
        }
      </div>
    )
  }
}

export default Parts