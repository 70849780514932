import React from 'react'
import STLViewer from 'stl-viewer'

const PartViewer = ({partUrl}) => (
  <STLViewer
    model={partUrl}
    width={250}
    height={250}
    modelColor='#4A90E2'
    backgroundColor='#F6F9F9'
    rotate={false} />
)

export default PartViewer
