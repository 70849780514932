import React from 'react'

const UploadError = ({onClose, children}) => (
  <div className="alert-qe alert-qe--danger text-center mb-4">
    <button type="button" className="close" onClick={onClose}>
      <span aria-hidden="true">&times;</span>
    </button>
    {children}
  </div>
)

export default UploadError
