import Immutable from 'immutable'

export const FinishRecord = Immutable.Record({
  id: null,
  name: '',
  pantone: false,
  annodize: false,
  plating: false,
  materialId: null
});

export class Finish extends FinishRecord {
  getColors() {
    let colorOptions = []
    if(this.annodize) {
      colorOptions.push(
        'Red',
        'Black',
        'Silver',
        'Blue',
        'Clear'
      )
    }

    if(this.plating) {
      colorOptions.push(
        'Nickel',
        'Chrome',
        'Zinc',
        'Copper'
      )
    }

    return colorOptions
  }
}

export const FinishMap = Immutable.OrderedMap;