import React from 'react'
import { connect } from 'react-redux'
import { newCreateQuote } from '../../actions/requests'
import Dropbox from '../common/Dropbox'
import ErrorMessage from '../common/notifications/ErrorMessage'

class Upload extends React.Component {
  state = {
    error: null
  }

  clearError = () => {
    this.setState({
      error: null
    })
  }

  setError = (error) => {
    this.setState({
      error: error
    })
  }

  render() {
    return (
      <div className="upload-view">
        <h1 className="upload-view__heading">Upload CAD File</h1>
        <h4 className="upload-view__subheading">STEP only</h4>
        { this.state.error && (
            <div style={{marginBottom: '1em'}}>
              <ErrorMessage message={this.state.error} onClose={this.clearError} />
            </div>
          )
        }
        <Dropbox
          onDrop={this.props.newCreateQuote}
          userId={this.props.user.id}
          onError={this.setError}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const user = state.user.toJS()

  return {
    user
  }
}

const mapDispatchToProps = (dispatch) => ({
  newCreateQuote: (userId, file, onProgress, onError) => dispatch(newCreateQuote(userId, file, onProgress, onError))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Upload)