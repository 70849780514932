import Immutable from 'immutable'
import { Part, PartMap } from '../models/Part'
import * as constants from '../constants'

const init = new PartMap();

const mergePartEntities = (state, newParts) => {
  return state.merge(newParts.map( part => new Part(part)))
}

export const partEntities = (state = init, action) => {
  switch(action.type) {
    case constants.UPDATE_PART_ENTITIES:
      return mergePartEntities(state, Immutable.fromJS(action.payload))
    case constants.SET_PRICE:
    case constants.SET_TOTAL_PRICE:
    case constants.SET_FAB_METHOD:
    case constants.SET_MATERIAL:
    case constants.SET_QUANTITY:
    case constants.SET_NAME:
    case constants.SET_COLOR:
    case constants.SET_FINISH:
    case constants.SET_FINISH_NOTES:
    case constants.SET_GENERAL_NOTES:
    case constants.SET_THREADED_HOLES:
    case constants.SET_INSERTS:
    case constants.SET_COLOR_CODE:
    case constants.SET_TEXTURE_CODE:
    case constants.SET_PART_VIEW:
    case constants.SET_NOTIFICATION:
    case constants.CLEAR_NOTIFICATION:
      return state.update(action.payload.partId.toString(), p => part(p, action))
    case constants.REMOVE_PART:
      return state.delete(action.payload.toString())
    default:
      return state
  }
}

const part = (state, action) => {
  switch(action.type) {
    case constants.SET_PRICE:
      return state.update('price', p => action.payload.price)
    case constants.SET_TOTAL_PRICE:
      return state.update('totalPrice', p => action.payload.totalPrice)
    case constants.SET_FAB_METHOD:
      return state.update('fabricationMethodId', fm => action.payload.fabricationMethodId)
    case constants.SET_MATERIAL:
      return state.update('materialId', m => action.payload.materialId)
    case constants.SET_QUANTITY:
      return state.update('quantity', q => action.payload.quantity)
    case constants.SET_NAME:
      return state.update('name', n => action.payload.name)
    case constants.SET_COLOR:
      return state.update('color', n => action.payload.color)
    case constants.SET_FINISH:
      return state.update('finish', n => action.payload.finish)
    case constants.SET_FINISH_NOTES:
      return state.update('finishNotes', n => action.payload.finishNotes)
    case constants.SET_GENERAL_NOTES:
      return state.update('generalNotes', n => action.payload.generalNotes)
    case constants.SET_THREADED_HOLES:
      return state.update('threadedHoles', th => action.payload.threadedHoles)
    case constants.SET_INSERTS:
      return state.update('inserts', i => action.payload.inserts)
    case constants.SET_COLOR_CODE:
      return state.update('colorCode', c => action.payload.colorCode)
    case constants.SET_TEXTURE_CODE:
      return state.update('textureCode', t => action.payload.textureCode)
    case constants.SET_PART_VIEW:
      return state.update('view', n => action.payload.view)
    case constants.SET_NOTIFICATION:
      return state.update('notification', n => action.payload.notification)
    case constants.CLEAR_NOTIFICATION:
      return state.update('notification', n => '')
    default:
      return state
  }
}


// Set activePartId at the root of the primary reducer 
// (this is done in index.js) so that we know which Part
// to change in the full wizard.
export const activePartId = (state = null, action) => {
  switch(action.type) {
    case constants.RECEIVE_PART:
      return action.payload
    case constants.SET_ACTIVE_PART_ID:
      return action.payload
    default:
      return state
  }
}