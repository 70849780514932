import React, { Component } from 'react'

class FeaturesCheckbox extends Component {
  state = {
    checked: this.props.checked,
    fieldValue: this.props.fieldValue === null ? 1 : this.props.fieldValue,
  }

  handleToggle = () => {
    this.setState(prevState => {
      const changeValue = prevState.checked ? null : prevState.fieldValue
      this.props.onChange(this.props.partId, changeValue)
      return { checked: !prevState.checked }
    })
  }

  handleInputChange = (event) => this.props.onChange(this.props.partId, event.target.value)

  render() {
    let quantityOptions = []

    for(var i=1; i<21; i++) {
      quantityOptions.push(<option key={i} value={i}>{i}</option>)
    }

    return (
      <div className="features-checkbox">
        <label className="features-checkbox__label">
          <input 
            type="checkbox" 
            checked={this.state.checked} 
            onChange={(e) => this.handleToggle('threadedHoles', e)} /> {this.props.label}
        </label>

        { 
          this.state.checked && (
            <div className="features-checkbox__quantity">
              <h6 className="field-title">Qty:</h6>
              <select value={this.props.fieldValue} 
                      onChange={(e) => this.handleInputChange(e)}
                      className="form-control form-control--small features-checkbox__select">
                { quantityOptions }
              </select>
            </div>
          )
        }
      </div>
    )
  }
}

FeaturesCheckbox.defaultProps = {
  checked: false
}

export default FeaturesCheckbox