import React, { useState, useCallback } from 'react'
import debounce from 'lodash/debounce'

const GeneralNotes = ({part, notes, onBlur, className}) => {
  const [generalNotes, setGeneralNotes] = useState(notes)

  const handleChange = (e) => {
    const { value: newValue } = e.target
    setGeneralNotes(newValue)
    saveState(newValue)
  }

  const saveState = useCallback(
    debounce(value => onBlur(part.id, value), 750),
    []
  )

  return (
    <div className={className}>
      <h6 className="field-title">Finish & Additional Notes</h6>

      <textarea
        value={generalNotes || ''}
        onChange={handleChange}
        className="engine-textarea"
        placeholder="Enter any other information you’d like to include about your part requirements here..."
      />
    </div>
  )
}

GeneralNotes.defaultProps = {
  notes: ''
}

export default GeneralNotes
