import Immutable from 'immutable'
import { Finish, FinishMap } from '../models/Finish'
import * as constants from '../constants'

const init = new FinishMap();

const mergeFinishEntities = (state, newFinishes) => {
  return state.merge(newFinishes.map((finish) => new Finish(finish)))
}

export const finishes = (state = init, action) => {
  switch(action.type) {
    case constants.UPDATE_FINISH_ENTITIES:
      return mergeFinishEntities(state, Immutable.fromJS(action.payload))
    default:
      return state
  }
}