import React from 'react'

const ErrorMessage = ({message, onClose}) => (
  <div className="alert-qe alert-qe--danger text-center">
    <button type="button" className="close" onClick={onClose}>
      <span aria-hidden="true">&times;</span>
    </button>
    <span>{message}</span>
  </div>
)

export default ErrorMessage