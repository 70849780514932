import React from 'react'
import PartViewer from './PartViewer'
import PartData from './PartData'

const PartInfo = ({
  part,
  metaPart,
  material,
  finish
}) => (
  <div className="flex-horizontal flex-horizontal--justify-start">
    <div>
      <PartViewer partUrl={metaPart.stlUrl} />
    </div>
    <div style={{marginLeft: '1em'}}>
      <PartData
        metaPart={metaPart}
        material={material}
        finish={finish}
        color={part.color}
      />
    </div>
  </div>
)

PartInfo.defaultProps = {}

export default PartInfo
