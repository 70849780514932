import { Schema, arrayOf } from 'normalizr'

/*************************
 * Schemas *
 *************************/

export const fabricationMethodSchema =  new Schema('fabricationMethods', { idAttribute: 'id' });
export const finishSchema =             new Schema('finishes', { idAttribute: 'id' });
export const resinSchema =              new Schema('resins', { idAttribute: 'id' });
export const materialSchema =           new Schema('materials', { idAttribute: 'id' });
export const quoteSchema =              new Schema('quotes', { idAttribute: 'id' });
export const partSchema =               new Schema('parts', { idAttribute: 'id' });
export const metaPartSchema =           new Schema('metaParts', { idAttribute: 'id' });
export const supplementalFileSchema =   new Schema('supplementalFiles', { idAttribute: 'id' });


fabricationMethodSchema.define({
  materials: arrayOf(materialSchema)
})

materialSchema.define({
  finishes: arrayOf(finishSchema)
})

quoteSchema.define({
  parts: arrayOf(partSchema)
})

partSchema.define({
  metaPart: metaPartSchema,
  supplementalFiles: arrayOf(supplementalFileSchema)
})