import React from 'react'
import Spinner from './Spinner'

const centeredStyle = {
  justifyContent: 'center', 
  marginTop: '1em'
}

const CenteredSpinner = () => (
  <div className="flex-horizontal" style={centeredStyle}>
    <Spinner />
  </div>
)

export default CenteredSpinner