import React from 'react'

import PartInfo from './PartInfo'
import PartPricing from './PartPricing'
import Button from '../../Button'

class PartHeader extends React.Component {
  render() {
    const {
      active,
      part,
      metaPart,
      material,
      finish,
      setQuantity,
      setActivePartId,
      deletePart
    } = this.props

    return (
      <div className="part-header">
        <div className="part-header-top">
          <h5 className="part-header-top__part-title">{metaPart.filename}</h5>
          <div className="part-header-top__right">
            <h6 className="field-title">Part #: <span className="part-header-top__part-id"> {part.id}</span></h6>
            <Button 
              text={<i className="fas fa-trash"></i>}
              type="plain"
              onClick={() => {
                const confirmation = confirm("Are you sure you want to remove this part from your order?");
                if(confirmation) {
                  deletePart(part.id);
                }
              }}
            />
            {
              active
                ? <Button
                    key={1}
                    text={<i className="fas fa-chevron-up"></i>}
                    onClick={() => setActivePartId(0)}
                    type="plain"
                    style={{color: '#3FA9F5', fontSize: '1.5em', padding: '0.25em'}}
                  />
                : <Button
                    key={2}
                    text={<i className="fas fa-chevron-down"></i>}
                    onClick={() => setActivePartId(part.id)}
                    type="plain"
                    style={{color: '#3FA9F5', fontSize: '1.5em', padding: '0.25em'}}
                  />
            }
          </div>
        </div>
        <div className="part-header-bottom">
          <div className="part-header-bottom__left">
            <PartInfo
              part={part}
              metaPart={metaPart}
              material={material}
              finish={finish}
            />
          </div>
          <div className="part-header-bottom__right">
            <PartPricing
              part={part}
              onQuantityChange={setQuantity}
              active={active}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default PartHeader
