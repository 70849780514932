import Immutable from 'immutable'
import { FabricationMethod, FabricationMethodMap } from '../models/FabricationMethod'
import * as constants from '../constants'

const init = new FabricationMethodMap();

const mergeFabMethodEntities = (state, newFabricationMethods) => {
  return state.merge(newFabricationMethods.map((fabMethod) => new FabricationMethod(fabMethod)))
}

export const fabricationMethods = (state = init, action) => {
  switch(action.type) {
    case constants.UPDATE_FABRICATION_METHOD_ENTITIES:
      return mergeFabMethodEntities(state, Immutable.fromJS(action.payload))
    default:
      return state
  }
}