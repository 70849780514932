import React from 'react'
import { Provider } from 'react-redux'
import configureStore from './store'
import Engine from './components/Engine'
import { fetchFabricationMethods, fetchQuote } from './actions/requests'
import { setUser } from './actions/actions'

const store = configureStore();

export default class App extends React.Component {
  componentDidMount() {
    store.dispatch(fetchFabricationMethods())

    if(this.props.user) {
      const parsedUser = JSON.parse(this.props.user)
      store.dispatch(setUser(parsedUser))
    }

    if(this.props.quoteId) {
      store.dispatch(fetchQuote(this.props.quoteId))
    }
  }

  render() {
    return (
      <Provider store={ store }>
        <Engine />
      </Provider>
    )
  }
}
