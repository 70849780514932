import React from 'react'
import cn from 'classnames'

const Spinner = ({hidden}) => (
  <div className={cn('spinner', {'invisible': hidden})}>
    <div className="double-bounce1"></div>
    <div className="double-bounce2"></div>
  </div>
)

export default Spinner;
