import React from 'react'
import Button from '../Button'

const PartFooter = ({onCancel, onSave}) => (
  <div className="part-footer">
    <Button style={{marginRight: '0.5em'}} text="Cancel" onClick={onCancel} />
    <Button text="Save" type="info" onClick={onSave} />
  </div>
)

export default PartFooter