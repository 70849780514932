import React, { useState, useCallback } from 'react'
import debounce from 'lodash/debounce'
import accounting from 'accounting'

const PartPricing = ({
  part,
  onQuantityChange,
  active
}) => {
  const [quantity, setQuantity] = useState(part.quantity)

  const handleQuantityChange = (e) => {
    const { value: newValue } = e.target;
    setQuantity(newValue);

    if(newValue !== '') {
      saveQuantityState(newValue);
    }
  }

  const saveQuantityState = useCallback(
    debounce(value => {
      onQuantityChange(part.id, value);
    }, 500),
    []
  )

  return (
    <div className="part-pricing">
      <table className="table table-sm table-borderless" >
        <tbody>
          <tr>
            <th scope="row">Part Quantity</th>
            <td>
              <input
                type="number"
                min="1"
                value={quantity}
                onChange={handleQuantityChange}
                className="form-control form-control--small"
                disabled={!active || part.materialId === null}
              />
            </td>
          </tr>
          <tr>
            <th scope="row">Parts x {part.quantity}<br />({accounting.formatMoney(part.price)} each)</th>
            <td>{accounting.formatMoney(part.totalPrice)}</td>
          </tr>
          <tr className="betajet-part-pricing__price">
            <th scope="row">Total</th>
            <td>{accounting.formatMoney(part.totalPrice)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default PartPricing
