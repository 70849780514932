import React from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'

import {
  setColor,
  setFinish,
  setColorCode,
  setThreadedHoles,
  setInserts,
  setMaterialId,
  setGeneralNotes,
  setActivePartId,
  setQuantity,
  clearNotification,
  setNotification
} from '../../actions/actions'

import {
  savePart,
  deletePart,
  uploadSupplementalFile,
  deleteSupplementalFile
} from '../../actions/requests'

import PartBody from '../common/parts/body/PartBody'
import PartFooter from '../common/parts/PartFooter'
import PartHeader from '../common/parts/header/PartHeader'
import ErrorMessage from '../common/notifications/ErrorMessage'

const watchedAttrs = [
  'color', 'finish', 'generalNotes', 'materialId',
  'quantity', 'prodQuantity','colorCode', 'threadedHoles',
  'inserts'
]

const partShouldUpdate = (part, prevPart) => {
  return watchedAttrs.some(attr => part[attr] !== prevPart[attr])
}

class Part extends React.Component {
  componentDidUpdate(prevProps) {
    if(partShouldUpdate(this.props.part, prevProps.part)) {
      this.debouncedSave();
    }
  }

  debouncedSave = debounce(() => {
    this.props.savePart(this.props.part)
  }, 1000)

  handleSave = () => {
    this.props.savePart(this.props.part)
  }

  render() {
    const {
      part,
      metaPart,
      material,
      finish,
      supplementalFiles,
      fabricationMethods,
      finishes,
      materials,
      setColor,
      setFinish,
      setColorCode,
      setThreadedHoles,
      setInserts,
      setMaterialId,
      setGeneralNotes,
      setActivePartId,
      setQuantity,
      clearNotification,
      setNotification,
      uploadSupplementalFile,
      deleteSupplementalFile,
      savePart,
      deletePart,
      active,
      threadedActive,
      insertsActive
    } = this.props

    return (
      <div className="part">
        <PartHeader
          active={active}
          part={part}
          metaPart={metaPart}
          material={material}
          finish={finish}
          setQuantity={setQuantity}
          setActivePartId={setActivePartId}
          deletePart={deletePart}
        />
        {
          active && (
            <div>
              {
                part.notification !== ''
                  && <ErrorMessage
                      message={part.notification}
                      onClose={() => clearNotification(part.id)}
                      />
              }
              <PartBody
                part={part}
                fabricationMethods={fabricationMethods}
                materials={materials}
                material={material}
                finishes={finishes}
                finish={finish}
                setColor={setColor}
                setFinish={setFinish}
                setColorCode={setColorCode}
                setThreadedHoles={setThreadedHoles}
                setInserts={setInserts}
                setMaterialId={setMaterialId}
                setNotification={setNotification}
                clearNotification={() => clearNotification(part.id)}
                threadedActive={threadedActive}
                insertsActive={insertsActive}
                supplementalFiles={supplementalFiles}
                setGeneralNotes={setGeneralNotes}
                uploadSupplementalFile={uploadSupplementalFile}
                deleteSupplementalFile={deleteSupplementalFile}
              />
              <PartFooter
                onCancel={() => setActivePartId(0)}
                onSave={this.handleSave}
              />
            </div>
          )
        }
      </div>
    )
  }
}

// Let the part component denormalize its own data from the
// redux state.
const mapStateToProps = (state, ownProps) => {
  const part = ownProps.part
  const user = state.user.toJS()
  const metaPart = state.entities.metaParts.filter(mp => mp.partId == part.id).first()
  const material = part.materialId !== null && state.entities.materials.get(part.materialId.toString())
  const finishes = material && material.finishes.map(finish => state.entities.finishes.get(finish.toString()))
  const finish = part.finish !== 0 && state.entities.finishes.get(part.finish.toString())
  const supplementalFiles = state.entities.supplementalFiles.filter( f => f.partId === part.id )

  const fabricationMethods = state.entities.fabricationMethods
  const materials = state.entities.materials

  const active = part.id === state.activePartId

  const threadedActive = part.threadedHoles !== null
  const insertsActive = part.inserts !== null

  return {
    user,
    part,
    metaPart,
    material,
    finish,
    supplementalFiles,
    fabricationMethods,
    materials,
    finishes,
    active,
    threadedActive,
    insertsActive
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setColor: (partId, color) => dispatch(setColor(partId, color)),
    setFinish: (partId, finish) => dispatch(setFinish(partId, finish)),
    setColorCode: (partId, colorCode) => dispatch(setColorCode(partId, colorCode)),
    setThreadedHoles: (partId, threadedHoles) => dispatch(setThreadedHoles(partId, threadedHoles)),
    setInserts: (partId, inserts) => dispatch(setInserts(partId, inserts)),
    setMaterialId: (id, mId) => dispatch(setMaterialId(id, mId)),
    setGeneralNotes: (id, notes) => dispatch(setGeneralNotes(id, notes)),
    setActivePartId: (partId) => dispatch(setActivePartId(partId)),
    setQuantity: (id, quantity) => dispatch(setQuantity(id, quantity)),
    clearNotification: (partId) => dispatch(clearNotification(partId)),
    setNotification: (id, notification) => dispatch(setNotification(id, notification)),
    deleteSupplementalFile: (fileId) => dispatch(deleteSupplementalFile(fileId)),
    uploadSupplementalFile: (partId, file) => dispatch(uploadSupplementalFile(partId, file)),
    savePart: (part) => dispatch(savePart(part)),
    deletePart: (id) => dispatch(deletePart(id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Part)
