import Immutable from 'immutable'
import { MetaPart, MetaPartMap } from '../models/MetaPart'
import * as constants from '../constants'

const init = new MetaPartMap();

const mergeMetaPartEntities = (state, newMetaParts) => {
  return state.merge(newMetaParts.map( metaPart => new MetaPart(metaPart)))
}

export const metaParts = (state = init, action) => {
  switch(action.type) {
    case constants.UPDATE_META_PART_ENTITIES:
      return mergeMetaPartEntities(state, Immutable.fromJS(action.payload))
    default:
      return state
  }
}