import request from 'superagent'
import humps from 'humps'
import { normalize, arrayOf } from 'normalizr'
import * as actions from './actions'
import * as schema from './schema'

let host = process.env.HOST_URL
let requestFormat = process.env.REQUEST_FORMAT

const baseUrl = `${requestFormat}://${host}/api/v1`
const resinsUrl = baseUrl + '/resins'
const fabMethodsUrl = baseUrl + '/fabrication_methods'
const partUrl = baseUrl + '/parts'
const quoteUrl = baseUrl + '/quotes'
const supplementalFileUrl = baseUrl + '/supplemental_files'
const shipmentUrl = baseUrl + '/shipments'
const orderUrl = baseUrl + '/orders'

/*************************
 * Async Action Creators *
 *************************/

export const fetchFabricationMethods = () => {
  return async dispatch => {
    dispatch(actions.requestFabricationMethods())

    const json = await request.get(fabMethodsUrl)

    const response = {
      fabricationMethods: humps.camelizeKeys(json.body),
    }

    let normalizedResponse = normalize(response, {
      fabricationMethods: arrayOf(schema.fabricationMethodSchema),
    })

    dispatch(actions.updateFabricationMethodEntities(normalizedResponse.entities.fabricationMethods))
    dispatch(actions.updateMaterialEntities(normalizedResponse.entities.materials))
    dispatch(actions.updateFinishEntities(normalizedResponse.entities.finishes))
  }
}

export const uploadPart = (quoteId, file) => {
  return dispatch => {
    dispatch(actions.beginPartUpload())
    dispatch(actions.setLoading(true))
    return request
      .post(partUrl)
      .field('quote_id', quoteId)
      .attach('file', file)
      .then( json => {
        const part = { part: humps.camelizeKeys(json.body) }

        let normalizedPart = normalize(part, { part: schema.partSchema })
        dispatch(actions.updatePartEntities(normalizedPart.entities.parts))
        dispatch(actions.updateMetaPartEntities(normalizedPart.entities.metaParts))
        dispatch(actions.receivePart(normalizedPart.result.part))
      })
      .then(json => dispatch(actions.setLoading(false)))
      .catch( error => {
        console.log("Error", error)
      })
  }
}

export const savePart = (part) => {
  const uploadPart = part.toJS()
  console.log(uploadPart)
  return dispatch => {
    dispatch(actions.beginPartUpload())
    dispatch(actions.setLoading(true))
    
    request
      .put(partUrl + '/' + uploadPart.id)
      .set('Content-Type', 'application/json')
      .send(humps.decamelizeKeys(uploadPart))
      .then(json => {
        let receivedPart = humps.camelizeKeys(json.body)
        let totalPrice = receivedPart.price * receivedPart.quantity
        dispatch(actions.setPrice(receivedPart.id, receivedPart.price));
        dispatch(actions.setTotalPrice(receivedPart.id, totalPrice));
      })
      .then(json => dispatch(actions.setLoading(false)))
      .catch( error => {
        console.log(error)
      });

  }
}

export const fetchQuote = (quoteId) => {
  return dispatch => {
    request.get(quoteUrl + '/' + quoteId)
      .then(json => {
        const quote = { quote: humps.camelizeKeys(json.body) }

        let normalizedQuote = normalize(quote, { quote: schema.quoteSchema })
        dispatch(actions.updatePartEntities(normalizedQuote.entities.parts))
        dispatch(actions.updateMetaPartEntities(normalizedQuote.entities.metaParts))

        if(normalizedQuote.entities.supplementalFiles) {
          dispatch(actions.updateSupplementalFileEntities(normalizedQuote.entities.supplementalFiles))
        }

        Object.values(normalizedQuote.entities.parts).forEach(part => {
          dispatch(actions.setTotalPrice(part.id, part.totalPrice))
        })

        dispatch(actions.receiveQuote(normalizedQuote.entities.quotes[quoteId]))
      })
  }
}

export const newCreateQuote = (userId = 0, file, onProgress, onError) => {
  return dispatch => {
    dispatch(actions.beginPartUpload())
    return request
      .post(quoteUrl)
      .field('user_id', userId)
      .attach('file', file)
      .on('progress', onProgress)
      .then( json => {
        const quote = { quote: humps.camelizeKeys(json.body) }

        let normalizedQuote = normalize(quote, { quote: schema.quoteSchema })
        console.log("Quote: ", normalizedQuote)
        const quoteId = normalizedQuote.result.quote
        dispatch(actions.updatePartEntities(normalizedQuote.entities.parts))
        dispatch(actions.updateMetaPartEntities(normalizedQuote.entities.metaParts))
        dispatch(actions.receiveQuote(normalizedQuote.entities.quotes[quoteId]))
        dispatch(actions.setActivePartId(normalizedQuote.entities.quotes[quoteId].parts[0]))
      })
      .catch( error => {
        console.log("Error Found", error)
        onError(error)
      })
  }
}

export const createQuote = (userId, partId) => {
  const quoteParams = {
    part_id: partId,
    quote: {
      user_id: userId
    }
  }

  return dispatch => {
    request.post(quoteUrl)
      .send(quoteParams)
      .then( json => {
        const quote = json.body
        dispatch(actions.setQuoteId(quote.id))
      })
      .catch( error => console.log("Error", error))
  }
}

export const uploadSupplementalFile = (partId, file) => {
  return dispatch => {
    dispatch(actions.setLoading(true))
    return request
      .post(supplementalFileUrl)
      .field('part_id', partId)
      .attach('file', file)
      .then( json => {
        const supplementalFile = { supplementalFile: humps.camelizeKeys(json.body) }

        let normalizedFile = normalize(supplementalFile, { supplementalFile: schema.supplementalFileSchema })
        dispatch(actions.updateSupplementalFileEntities(normalizedFile.entities.supplementalFiles))
      })
      .then(json => dispatch(actions.setLoading(false)))
      .catch( error => {
        console.log("Error", error)
      })
  }
}

export const deleteSupplementalFile = (fileId) => {
  return dispatch => {
    dispatch(actions.setLoading(true))
    return request
      .del(supplementalFileUrl + '/' + fileId)
      .set('Content-Type', 'application/json')
      .then( json => {
        dispatch(actions.removeSupplementalFile(fileId))
      })
      .then(json => dispatch(actions.setLoading(false)))
      .catch(error => console.log("Error", error))
  }
}

export const deletePart = (partId) => {
  return dispatch => {
    return request
      .del(partUrl + '/' + partId)
      .set('Content-Type', 'application/json')
      .then( json => {
        dispatch(actions.removePart(partId))
      })
      .catch(error => console.log("Error", error))
  }
}

/*************************
 * Standard API Requests *
 *************************/

export const createShipment = (params, onSuccess, onError) => {
  return request
          .post(shipmentUrl)
          .send(params)
          .set('Content-Type', 'application/json')
          .on('error', onError)
          .then(resp => resp.body)
          .then(onSuccess)
}

export const createOrder = (params, onSuccess, onError) => {
  return request
          .post(orderUrl)
          .send(params)
          .set('Content-Type', 'application/json')
          .on('error', onError)
          .then(resp => resp.body)
          .then(onSuccess)
}

