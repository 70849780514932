import React from 'react'

class Materials extends React.Component {
  state = {
    filter: 'both'
  }

  setFilter = (filter) => {
    this.setState({
      filter: filter
    })
  }

  render() {
    const { part, materials, setMaterialId } = this.props
    const { filter } = this.state

    const filteredMaterials = filter === 'both' 
      ? materials 
      : materials.filter(m => m.materialType === filter)

    return (
      <div className="materials-view">
        <div>
          <h6 className="field-title">Material Type</h6>
          <div className="form-check">
            <label>
              <input
                type="radio"
                className="form-check-input"
                onChange={() => this.setFilter('metal')}
                checked={filter === 'metal'}
              />
              Metal
            </label>
          </div>
          <div className="form-check">
            <label>
              <input
                type="radio"
                className="form-check-input"
                onChange={() => this.setFilter('plastic')}
                checked={filter === 'plastic'}
              />
              Plastic
            </label>
          </div>
          <div className="form-check">
            <label>
              <input
                type="radio"
                className="form-check-input"
                onChange={() => this.setFilter('both')}
                checked={filter === 'both'}
              />
              Both
            </label>
          </div>
        </div>
        <div style={{marginLeft: '2em'}}>
          <h6 className="field-title">Materials</h6>

          <div className="columns columns--c3">
            {
              filteredMaterials.valueSeq().map(material => {
                return (
                  <div className="form-check" key={material.id}>
                    <label>
                      <input
                        type="radio"
                        className="form-check-input"
                        onChange={() => setMaterialId(part.id, material.id)}
                        checked={material.id === this.props.part.materialId}
                      />
                      {material.name}
                    </label>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div style={{marginLeft: '2em'}}>
          <h6 className="field-title">Description</h6>
          <p>{this.props.material.description}</p>
        </div>
      </div>
    )
  }
}

export default Materials
